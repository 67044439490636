/*Modal*/
.modal{
    background-color: #00000094;
  }
  .modal.show {
    transition: transform 0.3s ease-out;
    display: block;
  }
  .modal-header{
    justify-content: center;
  }
  .modal-footer{
    flex-wrap: nowrap;
  }
  .modal-body{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 1rem 0 1rem 0;
  }
  .modal-body > input {
    height: 50px;
    border: 1px solid #cbc9c9;
    border-radius: 8px;
    width: 100px;
    font-size: 23px;
    font-weight: 700;
    text-align: center;
  }
  .modal-body > span{
    font-size: 17px;
    text-align: center;
    font-weight: 100;
  }
  .modal-btn-negative{
    width: 100%;
    background-color: #f5f5f5;
    height: 35px;
    border-radius: 7px;
    font-weight: 600;
    font-size: 14px;
    border: 2px solid var(--primary-color);
    color: var(--primary-color);
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
  .modal-btn-positive{
    background-color: var(--primary-color);
    border: 2px solid var(--primary-color);
    border-radius: 7px;
    font-size: 14px;
    color: white;
    height: 35px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    &:disabled{
      background-color: #a7a7a7;
      border-color: #a7a7a7;
      opacity: 0.5;
      cursor: no-drop;
    }
  }