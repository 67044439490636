@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

input:focus {
  box-shadow: 0 0 5px 2px var(--primary-color, 0.5);
  border-color: var(--primary-color);
}
input:checked:focus,
input:not(:checked):focus {
  box-shadow: none;
}

input[type="checkbox"]:checked {
  accent-color: var(--primary-color);
}

/* Adicionando um estilo mais customizado usando ::before e ::after */
input[type="checkbox"] {
  /* Esconder a checkbox original */
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 20px;
  height: 20px;
  background-color: #fff;
  border: 2px solid #ccc;
  border-radius: 3px;
  position: relative;
}

input[type="checkbox"]:checked::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  border-radius: 2px;
  width: 12px;
  height: 12px;
  background-color: var(--primary-color);
  transform: translate(-50%, -50%);
}


/*#region Variaveis Globais*/
:root {
}
.green{
  color: green;
}
/*#endregion*/

/*#region Global*/
*{
    padding: 0px;
    margin: 0px;
    font-family: "Poppins", sans-serif;

}


html, body {
  transition: none;
  --primary-color: #652969;
  --color-button-positive: #652969;
  --color-button-negative: #652969;
}

.fa, .fas, .fa-brands, .fa-classic, .fa-regular, .fa-sharp, .fa-solid, .fab, .far {
  font-family: FontAwesome !important;
}
.body-no-scroll {
  overflow-y: hidden;
}
a{
    text-decoration: none;
}
.row-center{
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: row;
}
::-webkit-scrollbar{
    width: 0px;
    height: 0px;
}
input{
  outline-color: var(--primary-color);
}


/*#endregion*/
/* .modal-dialog-centered{
  align-items: flex-start !important;
} */

.modal-header{
  padding: 10px !important;
}
.modal.show > .modal-dialog > .modal-content{
  animation: animation 350ms ease;
}

@keyframes animation {
  0%{ transform: translateY(-100%) scale(0.2);}
  100%{ transform: translateY(0%) scale(1);}
}

/*#region Page main*/
/*Address*/

.address{
  padding: 7px;
}
.address__title{
  padding: 12px;
  background-color: #e9e9e9;
  display: flex;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}
.address__title > div{
  font-size: 16px;
  color: black;
}
.address__body{
  padding: 12px;
  width: 100%;
  display: flex;
  border-top: 1px solid #d9d9d9;
  flex-direction: column;
  border-bottom: 1px solid #d9d9d9;
  border-left: 1px solid #d9d9d9;
  border-right: 1px solid #d9d9d9;

}

.address_option{
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #e1e1e1;
  margin-bottom: 15px;
  padding-bottom: 15px;
}


.address__choice__body{
  width: 100%;
  display: flex;
  border-top: 1px solid #d9d9d9;
  flex-direction: column;
  border-bottom: 1px solid #d9d9d9;
  border-left: 1px solid #d9d9d9;
  border-right: 1px solid #d9d9d9;
}
.address_choice{
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  border-bottom: 1px solid #dddddd;
}
.address_option__title{
  font-size: 14px;
  color: #575757;
}



.address_content{
  text-align: left;
  font-size: 13px;
  line-height: 16px;
  flex-grow: 1;
  color: gray;
}
.address_buttons{
  margin-top: 10px;
  display: flex;
  gap: 25px;
}
.button-delete{
  background-color: white;
  border: 2px solid red;
  box-shadow: 0px 3px 4px 1px rgb(10 10 10 / 25%);
  color: red;
  border-radius: 2px;
  padding: 4px;
  font-weight: 600;
  width: 60px;
  height: 22px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.button-edit{
  background-color: var(--color-button-positive);
  border: 2px solid var(--color-button-positive);
  box-shadow: 0px 3px 4px 1px rgb(10 10 10 / 25%);
  color: white;
  border-radius: 2px;
  padding: 4px;
  font-weight: normal;
  width: 60px;
  height: 22px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.address_button{
  /* padding: 10px; */
  padding-bottom: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  /* margin-top: 10px; */
}
.address_button > button {
  border: 2px solid var(--color-button-negative);
  color: var(--color-button-negative);
  padding: 5px;
  border-radius: 8px;
  font-size: 13px;
  box-shadow: 0px 3px 4px 1px rgb(10 10 10 / 25%);
  width: 180px;
  height: 30px;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
}

/*#region Menu/Cardapio*/
.main-container{
    width: calc(100% - 300px);
    margin-left: 300px;
    /* padding-top: 80px; */
}
.main-container.responsive{
    width: 100%;
    margin-left: 0px;
}

.lista-category{
  width: 1200px;
  margin: 0 auto;
  padding-top: 10px;

}
.painel{
  /* margin-bottom: 21px; */
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 1px 1px rgba(0,0,0,0.5);
}
.title-painel{
  border-top-right-radius: 3px;
  border-top-left-radius: 3px;
  background-color: #fff;
  /* border-top: 2px solid #212121; */
  border-top: 2px solid var(--primary-color);
}
.title-painel > h2{
  font-size: 20px;
  /* color: #212121; */
  color: var(--primary-color);
  padding-left: 12px;
  /* font-weight: 600; */
  margin-top: 0.5rem;
}
.products{
  padding: 7px;
  display: flex;
  flex-wrap: wrap;
}
.product-card{
  padding-left: 10px;
  padding-right: 10px;
  width: 50%;
  height: 130px;
  display: inline-block;
  cursor: pointer;
}
.product-card > a{
  height: 100%;
  display: block;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 3px;
  padding-right: 5px;
  border-bottom: 2px solid #e9e9e9;
  text-decoration: none;

}
/* .product-card > a:hover{
  background-color: #edebeb;
} */
.body-card{
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 0.7rem;
}
.description-card{
  gap: 3px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  vertical-align: top;
}
.info-card{
  width: 100%;
  height: 98%;
  gap: 8px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-evenly;
}
.info-card > span {
  color: var(--primary-color);
}
.value-card > .value-card__text{
  color: #616161;
  width: 56px;
}
.value-card > .value-card__value{
  color: #652969;
}
/*Modal Cart*/
.offcanvas-backdrop.show{
  opacity: 0;
}


.description-card-placeholder{
  width: 100%;
  background-color: rgb(165, 164, 164);
  height: 100px;
  border-radius: 4px;
  animation: pulse 1s infinite;
  animation-direction: normal;
}

@keyframes pulse {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

.image-card{
  position: relative;
  background-color: #c5c5c5;
  border-radius: 6px;
  width: 100px;
  height: 100px;
}
.image-card::after{
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  transform: translateX(-80%);
  background: linear-gradient(90deg, transparent, hsla(0, 0%, 100%, .35), transparent);
  opacity: 1;
  animation: loading 1.5s 30;
  left: 1px;
}
.image-card.not-animation::after{
  display: none;
}
.image-card > img{
 width: 100px;
 border-radius: 6px;
 height: 100%;
 border: 1px solid #cbcbcb;
 object-fit: cover;
 background-color: white;
 opacity: 0;

 position: relative;
}
.complete{
  opacity: 1 !important;
  z-index: 9;
}

@keyframes loading {
  to {
    transform: translateX(100%);
  }
}
/* .image-wrapper > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
} */
/* .image-card > img:after{
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  transform: translateX(-80%);
  background: linear-gradient(90deg, transparent, hsla(0, 0%, 100%, .35), transparent);
  opacity: 1;
  animation: loading 1.5s infinite;
} */

.name-card{
  letter-spacing: .2px;
  white-space: pre-line;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  vertical-align: top;
  padding-right: 4px;
  overflow: hidden;
  position: relative;
  font-size: 16px;
  line-height: 20px;
  color: #535353;
}
.detail-card{
  color: #36454fbf;
  font-size: 12px;
  line-height: 1;
  height: 25px;
  letter-spacing: .2px;
  overflow: hidden;
  white-space: inherit;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.price-card{
  font-weight: 400px;
  position: relative;
  font-size: 20px;
  color: #23262e;
}
/*#endregion*/


/*#endregion*/





@media screen and (max-width: 1510px) {

  .lista-category{
    width: 1100px;
  }
}
@media screen and (max-width: 1418px) {

  .lista-category{
    width: 1000px;
  }
}

@media screen and (max-width: 1300px) {

    .lista-category{
      width: 860px;
    }
}
@media screen and (max-width: 1168px) {

  .side-bar{
    display: none;
  }



  .image-card{
    position: relative;
    background-color: #c5c5c5;
    border-radius: 6px;
    width: 100px;
    height: 100px;
  }
  .image-card::after{
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    transform: translateX(-80%);
    background: linear-gradient(90deg, transparent, hsla(0, 0%, 100%, .35), transparent);
    opacity: 1;
    animation: loading 1.5s 30;
    left: 1px;
  }
  .image-card.not-animation::after{
    display: none;
  }
  .image-card > img{
   width: 100px;
   border-radius: 6px;
   height: 100%;
   border: 1px solid #cbcbcb;
   object-fit: cover;
   background-color: white;
   opacity: 0;
  
   position: relative;
  }


  .top-bar{
    display: block;
  }
  /* .main-container{
    padding-top: 119px;
  } */
  .main-container.not-top-menu{
    padding: 0px;
  }

  }
@media screen and (max-width: 985px) {

    /*top -menu*/

    .lista-category{
      width: 750px;
    }



    /*Card*/
}
@media screen and (max-width: 846px) {


    /*top -menu*/
    .top-menu{
        height: 100px;
    }
    .search-bar-input{
        width: 900px;
    }
    .menu-search{
        width: 100%;
    }



    .img-company-image{
      width: 60px;
      margin-right: 0px;
    }
    .company-info-mobile{
      display: block;
    }
    .search-bar{
      width: 80%;
    }
    .menu-container{
      background-color: #efefef;
      /* margin-top: 50px; */
    }
    .company-info-mobile{
      padding-right: 15px;
    }


    /*top bar*/
    .top-bar{
        top: 230px;
    }

    /*main*/
    /* .main-container{
      padding-top: 235px;
    } */
    .menu-container{
        flex-direction: column;
    }

    /*menu-bottom*/
    .menu-bottom{
      display: block !important;
    }
}
@media screen and (max-width: 768px) {
  .lista-category{
    margin: 0 7px;
    width: auto;
  }
  .products{
    padding-top: 0px;
  }
  .product-card{
    width: 100%;
    margin-left: 0px;
    margin-right: 0px;
    padding-left: 0;
    padding-right: 0;
  }
  /* .product-card:first-child{
    margin-top: 0px;
  }
  .product-card:first-child > a{
    padding-top: 0px;
  } */



}
@media screen and (max-width: 480px){

  .top-bar{
    position: absolute;
  }



  /*menu-top*/
    .menu-top-buttons{
        display: none;
    }
    .menu-search{
      flex-direction: column;
    }

    .company-info-mobile{
      padding-right: 0px;
    }
    .img-company-image{
      width: 85px;
    }
    .menu-container{
      margin-top: 0px;
    }
    .search-bar{
      width: 100%;
    }


    /*main/card*/
    .description-card{
      padding-left: 0px;
    }
    .body-category{
      justify-content: center;
    }


}
@media screen and (max-width: 380px) {

}
@media screen and (max-width: 350px) {

}


.toast-top-right {
  right: 30px;
}
.toast-container{
  width: 100% !important;
  display: flex !important;
  justify-content: flex-end !important;
  flex-direction: column !important;
}
.toast-container > .toast-success{
  background-color: white;
  box-shadow: 0 4px 15px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.1), inset 0 2px 0 0 #4CAF50 !important;
  /* background-image: url(assets/checkmark.svg); */
  background-image: url(assets/checked.png);
}
.toast-container .ngx-toastr {
  opacity: 1;
  max-width: 500px;
  width: 100% !important;
  border-radius: 0px !important;
}
.toast-container .toast-success .toast-progress{
  background: #4CAF50;
  opacity: 1;
  height: 2px;
}







/*Theme-dark settings*/

.theme-dark{
  background-color: #21252a !important;
}
.theme-dark .fa.fa-chevron-left{
  color: #ff79ff !important;
}
.theme-dark .navegation-header {
  border-bottom: 2px solid #4d4c4c !important;
  background-color: #21252a !important;
}
.theme-dark textarea {
  background-color: #212020 !important;
  color: #b9b6b6;
}

.theme-dark .button-observation{
  color: #b9b9b9 !important;
}
.theme-dark .content-observation{
  color: #bdbdbd !important;
}

/*#region Top Menu and Top Menu Two*/
  .theme-dark .top-menu{
    background-color: #212020 !important;
  }
  .theme-dark .menu-company__name{
    color: #efefef !important;
  }
  .theme-dark .menu-top-buttons > a {
    color: #d7d7d7 !important;
  }
/*#endregion*/

/*#region Side bar*/
  .theme-dark .side-bar{
    border-right: 1px solid #2f2f2f !important;
  }
  .theme-dark .links-navegation{
    color: #ebe9e9 !important;
  }
  .theme-dark .links-navegation:hover{
    background-color: #a313a3 !important;
  }
  .theme-dark .links-navegation.active{
    background-color: #a313a3 !important;
  }
  .theme-dark .menu-side-bar > ul > li{
    border-top: 1px solid #353535 !important;
  }
  .theme-dark .menu-side-bar > ul > li:not(:first-child){
    border-top: 1px solid #353535 !important;
  }
  .theme-dark .menu-side-bar > ul > li:last-child {
    border-bottom: 1px solid #353535 !important;
  }

/*#endregion*/

/*#region menu*/

.theme-dark .menu,
.theme-dark .painel,
.theme-dark .title-painel{
  background-color: #21252a !important;
}

/*Modal empresa in Menu*/
.theme-dark .description-title{
  background-color: #16181a !important;
}
.theme-dark .description-body > div > span {
  color: #b9b6b6 !important;
}
.theme-dark .icon-modal > i {
  color: #d5d5d5 !important;
}
/*********/



/*Top menu*/
.theme-dark .collapse-box{
  background-color: #21252a !important;
  color: #b9b6b6 !important;
}
.theme-dark .warning-identification{
  background-color: #21252a !important;

}
.theme-dark .warning-identification > span{
  color: #d5d5d5 !important;

}
.theme-dark .button-for-search > i{
  color: #b9b6b6;
}
.theme-dark .div-input {
  color: #b9b6b6;
}
.theme-dark .div-input > input{
  background-color: #212020;
  color: #b9b6b6;
}


/*******/


/*Div Header*/

.theme-dark .div-header{
  background-color: #21252a !important;
}
.theme-dark .div-company > span {
  color: #afafaf !important;
}
/* .theme-dark .div-button-for-top > button{
  background-color: #212020;
} */
.theme-dark .div-button-for-top > button > i{
  color: #ff79ff !important;
}
.no-transition {
  transition: none !important;
}

/********/


/*top bar*/
.theme-dark .span-company{
  color: #adadad !important;
}

.theme-dark .top-bar > ul{
  background-color: #15171a !important;
}
.theme-dark .links > button > span {
  color: #b5b4b4;
}
.theme-dark .links > button{
  background-color: #15171a !important;
}
.theme-dark .active-nav-top{
  color: #ff79ff !important;
}
.theme-dark .line-list-top-bar.active-bar{
  background-color: #ff79ff !important;
}

/*********/


.theme-dark .painel{
  box-shadow: 0 1px 1px rgb(255 255 255 / 50%);
}
.theme-dark .title-painel{
  border-top: 2px solid #ff79ff;
}
.theme-dark .product-card > a {
  border-bottom: 2px solid #373737;
}
.theme-dark .value-card__text{
  color: #898989 !important;
}
.theme-dark .value-card__value{
  color: #ff79ff !important;
}

.theme-dark h2,
.theme-dark h3,
.theme-dark h4,
.theme-dark h5
{
  color: #bfbfbf !important;
}

.theme-dark .name-card{
  color: #fff;
}
.theme-dark .detail-card{
  color: #898989;
}
/*#endregion*/

/*#region bottom-menu*/

.theme-dark .menu-bottom{
  background-color: #21252a !important;
}
.theme-dark .button-bottom{
  color: #d7d7d7 !important;
}
.theme-dark .active .button-bottom{
  color: #ff79ff !important;
}
.theme-dark .active .title-icon{
  color: #ff79ff !important;
}
.theme-dark .active .bar{
  background-color: #ff79ff !important;
}

/*#endregion*/

/*#region Cart*/

.theme-dark .cart-header{
  color: #b5b4b4 !important;
}
.theme-dark .cart-header__title{
  color: #b5b4b4 !important;
}
.theme-dark .cart-list-item__category > span{
  color: #b5b4b4 !important;
}
.theme-dark .cart-header__div-button > button{
  background-color: #21252a !important;
}
.theme-dark .cart-list-item{
  color: #b5b4b4 !important;
}
.theme-dark .cart-list-item__description__name{
  color: white !important;
}
.theme-dark .cart-list-item__description__price{
  color: #ff79ff !important;
}
.theme-dark .box-product-cart{
  border-bottom: 1px solid #4d4d4d !important;
}
.theme-dark .buttons-item-list{
  border: 1px solid #616161 !important;
}
.theme-dark .button-plus{
  background-color: #21252a !important;
  color: #ff79ff !important;
}
.theme-dark .button-down.activated{
  background-color: #21252a !important;
  color: #ff79ff !important;
}
.theme-dark .button-down.activated > .btn-negative{
  color: #ff79ff !important;
}
.theme-dark .cart-footer__fixed{
  background-color: #21252a !important;
}
.theme-dark .cart-footer__button > button{
  background-color: #d125d1 !important;
  color: #fff !important;
}
.theme-dark .button-edition > button {
  background-color: #fb42fb !important;
}
.theme-dark .cart-list-item__observation > span{
  border-top: 1px solid #3d3d3d;
}


/*#endregion*/

/*#region Pedidos*/
.theme-dark .box-orders__order{
  border-bottom: 1px solid #454545 !important;
}

.theme-dark .span-order{
  color: #d7d2d2 !important;
}
.theme-dark .body-order{
  border: 1px solid #494949 !important;
}
.theme-dark .body-order__order{
  color: #b9b6b6 !important;
}
.theme-dark .button-detail-order > button {
  background-color: #21252a !important;
  border: 2px solid #fb42fb!important;
  color: #fb42fb!important;
}
.theme-dark .price-order > span {
  color: #b9b6b6 !important;
}

/*#endregion*/

/*#region Detalhes do Pedido*/

.theme-dark .order-number{
  color: #d7d2d2 !important;
}
.theme-dark .order-date-time{
  color: #898989 !important;
}
.theme-dark .box-tittle{
  background-color: #5d5d5d !important;
}
.theme-dark .box-tittle > div{
  color: #fff !important;
}
.theme-dark .box-body{
  border-left: 1px solid #696969 !important;
  border-right: 1px solid #696969 !important;
  border-bottom: 1px solid #696969 !important;
}
.theme-dark .total-value{
  color: #898989 !important;
}
.theme-dark .content-total-value{
  color: #d7d2d2 !important;
}
.theme-dark .order-history__box.active > .order-history__box__content > div {
  background-color: #d125d1 !important;
  border-color: #d125d1 !important;
}
.theme-dark .order-history__box.active > .order-history__box__status{
  color: #d125d1 !important;
}
.theme-dark .order-history__box.active > .order-history__box__time{
  color: #d125d1 !important;
}




/*#endregion*/

/*#region detalhes do produto*/

.theme-dark .title-product-detail{
  color:#fff !important;
}
.theme-dark .product-detail > .navegation-header{
  border-bottom: none !important;
}
.theme-dark .product-detail > .navegation-header > .navegation-header-button > div > i{
  color: #d125d1 !important;
}
.theme-dark .description-product-detail{
  color: #898989 !important;
}
.theme-dark .box-title{
  background-color: #15171a !important;
}
.theme-dark .box-title-content{
  color: #b5b4b4 !important;
}
.theme-dark .button-down{
  background-color: #21252a !important;
  color: white;
}
.theme-dark .bottom-navegation{
  background-color: #21252a !important;
}
.theme-dark .counter > .counter-div{
  color: #b5b4b4;
}
.theme-dark .seletor-info-detail > span{
  color: white !important;
}
.theme-dark .seletor-info-detail > p{
  color: #b5b4b4 !important;
}
.theme-dark .selector-price > span{
  color: #d125d1 !important;
}
.theme-dark .bottom-counter > button {
  background-color: #d125d1 !important;
}

/*#endregion*/

/*#region User Data*/

.theme-dark .navegation-header > .box-title{
  background-color: #21252a !important;
}
.theme-dark .user-data > .box-data > span{
  color: #b5b4b4 !important;
}
.theme-dark .user-data > .box-data > input {
  background-color: #21252a !important;
  color: white;
}
.theme-dark .custom-checkbox > span:left {
  background-color: #d125d1 !important;
}
.theme-dark .box-checkRadio > span {
  color: #b5b4b4 !important;
}
.theme-dark .box-cpf.show > span {
  color: #b5b4b4 !important;
}
.theme-dark .box-cpf.show > input {
  background-color: #21252a !important;
  color: white;
}
.theme-dark .warning{
  color: #8b8a8a !important;
}
.theme-dark .bottom-navegation__box > button.not-disabled {
  background-color: #d125d1 !important;
  border: none;
}

/*#endregion*/

/*#region Theme Dark*/
.theme-dark .address__title{
  background-color: #5d5d5d !important;
}
.theme-dark .address__title > div{
  color: #fff !important;
}
.theme-dark .address__body > div > span{
  color: #fff !important;
}
.theme-dark .button-delete{
  background-color: #21252a !important;
}
.theme-dark .address_option {
  border-bottom: 1px solid #4d4d4d !important;
}
.theme-dark .address__body{
  border-left: 1px solid #393838 !important;
  border-right: 1px solid #393838 !important;
  border-top: 1px solid #393838 !important;
  border-bottom: 1px solid #393838 !important;
}
.theme-dark .address_button > button {
  background-color: #21252a !important;
  border: 2px solid #ff79ff !important;
  color: #ff79ff !important;
}
.theme-dark .form-payment__title{
  background-color: #5d5d5d !important;
}
.theme-dark .form-payment__title > div{
  color: #fff !important;
}
.theme-dark .user-detail > .user-detail__one{
  color: #b5b4b4 !important;
}
.theme-dark .user-detail > .user-detail__two{
  color: white !important;
}
.theme-dark .user-detail > .user-detail__tree{
  color: #e3e3e3 !important;
}
.theme-dark .user-detail__div-button > button{
  background-color: #21252a !important;
  border: 2px solid #ff79ff  !important;
  color: #ff79ff  !important;
}
.theme-dark .order-content__info__details > span{
 color: #fff !important;
}
.theme-dark .order-content__info__details > div{
  color: #ff79ff !important;
}
.theme-dark .form-payment-option__tittle{
  color: #b5b4b4 !important;
}
.theme-dark .box-button >button{
  background-color: #d125d1 !important;
  border: none !important;
}
.theme-dark .box-button > .disabled-positive{
  outline: none;
  background-color: #939393 !important;
  opacity: .5;
}
.theme-dark .fa-solid.fa-phone {
  color: #d125d1 !important;
}
.theme-dark .checkmark.show::after{
  background-color: #d125d1 !important;
}
.theme-dark .order{
  border-left: 1px solid #393838 !important;
  border-right: 1px solid #393838 !important;
  border-top: 1px solid #393838 !important;
  border-bottom: 1px solid #393838 !important;
}
.theme-dark .order-item__category > span{
  color: #b5b4b4 !important;
}
.theme-dark .form-payment__body{
  border: 1px solid #393838 !important;
}
.theme-dark .button-content{
  border: 2px solid #ff79ff !important;

}
.theme-dark .div-icon-button{
  color: #ff79ff !important;
}
.theme-dark .button-content > span {
  color: #ff79ff !important;
}

/*Balcão*/
.theme-dark .addres_choice__title > div{
  color: #cfcfcf !important;
}
/* .theme-dark .form-payment__body.active-body{
  border: 2px solid #ff79ff !important;
}*/
.theme-dark .form-payment-option{
  border-bottom: 1px solid #393838 !important;
}

/*Box cpf*/
.theme-dark .box-cpf > span{
  color: #cfcfcf !important;
}
.theme-dark .box-cpf > input {
  background-color: #21252a !important;
  color: white;
}

/*#endregion*/

/*#region Modais*/


.theme-dark .modal-content{
  background-color: #21252a !important;
  color: #d9d9d9 !important;
}
.theme-dark .modal-header{
  border-bottom: 1px solid #4d4d4d !important;
}
.theme-dark .modal-header > h1 {
  color: white;
}
.theme-dark .modal-body > input{
  background-color: #212020 !important;
  color: #b9b6b6;
}
.theme-dark .observation-area{
  border-top: 1px solid #4d4d4d !important;
}
.theme-dark .observation-area > textarea{
  background-color: #212020 !important;
  color: #b9b6b6;
}
.theme-dark .modal-footer{
  border-top: 1px solid #4d4d4d !important;

}
.theme-dark .btn-edit{
  background-color: #21252a !important;
  color: #d125d1 !important;
  border: 2px solid #d125d1 !important;
}
.theme-dark .btn-confirmation{
  background-color: #d125d1 !important;
}
.theme-dark .btn-cancel{
  background-color: #21252a !important;
  color: #d125d1 !important;
  border: 2px solid #d125d1 !important;
}
.theme-dark .btn-confirm{
  background-color: #d125d1 !important;
}

/*#endregion*/

/*#region consumo*/
.theme-dark .button-split__content > span{
  color: #d9d9d9 !important;
}
.theme-dark .fa-user-plus{
  color: #d125d1 !important;
}
.theme-dark .fa-solid.fa-user{
  color: #d125d1 !important;
}

.theme-dark .div-value{
  color: white !important;
}
.theme-dark .people{
  border: 1px solid #4d4d4d !important;
}
.theme-dark .body-box-change{
  border: 1px solid #4d4d4d !important;
}
.theme-dark .buttons-change{
  background-color: #21252a !important;
  color: white;
  border: 1px solid white;
}
.theme-dark .div-result-change > button {
  background-color: #212020 !important;
}
.theme-dark .button-call-comand{
  background-color: #d125d1 !important;
  border: 2px solid #d125d1 !important;
}
.theme-dark .button-call-garçom{
  background-color: #21252a !important;
  color: #d125d1 !important;
  border: 2px solid #d125d1 !important;
}
.theme-dark .box-button-menu{
  background-color: #21252a !important;
  box-shadow: 0px -9px 20px 0px rgb(10 10 10 / 44%);
}
.theme-dark .item__observation > span{
  color: #b5b4b4 !important;
}
.theme-dark .info__detail__price {
  color: #d125d1 !important;
}

/*#endregion*/

/*#region BoxLoading*/
.theme-dark .box-loading__content
{

}
/*#endregion*/

/*#region Endereço*/
.theme-dark .text-initial{
  color: #b3b3b3 !important;
}
.theme-dark .address-content > span{
  color: #b3b3b3 !important;
}
.theme-dark .button-my-localization > button {
  background-color: #21252a !important;
  border: 2px solid #ff79ff !important;
  color: #ff79ff !important;
}
.theme-dark .input-address{
  background-color: #21252a !important;
  color: white !important;
}

/*#endregion*/

@media screen and(max-width: 846) {
  .theme-dark .menu-container{
    background-color: #212020 !important;
  }
  .button-light-theme-desktop{
    display: none;
  }
  .button-dark-theme-desktop{
    display: none;
  }
}

/*toast*/

.toast-success{
  color: white !important;
  background-color: #269526 !important;
  height: auto;
  opacity: 1;
  font-size: 14px;
}
.toast-top-right{
  top: 0px !important;
  right: 0 !important;
}
.toast-title{
  color: white !important;
  font-weight: 400 !important;
}
.toast-container{
  color: white !important;
}
.toast-message{
  color: white !important;
}
.toast-error{
  font-size: 14px;
  height: auto;
  background-color: #BD362F;
  position: relative;
  overflow: hidden;
  margin: 0 0 6px;
  padding: 15px 15px 15px 50px;
  width: 300px;
  border-radius: 3px 3px 3px 3px;
  background-position: 15px center;
  background-repeat: no-repeat;
  background-size: 24px;
}

@media screen and(max-width: 1168px) {
  .toast-top-right{
    top: 110px !important;
  }
}

@media screen and(max-width: 846px) {
  .toast-top-right{
    top: 0 !important;
    right: 0 !important;
  }
}


html, body { height: 100%; }
/* body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; } */
